<template>
  <link rel="stylesheet" href="https://cdn.staticfile.org/font-awesome/4.7.0/css/font-awesome.css">
  <div class="bac">
    <div class="top">
      <div class="left">
        <div class="left_top">
          <p>{{ this.Info.typeSecond }}</p>
          <p>{{ this.Info.projectName }}</p>
        </div>
        <div class="left_bottom">
          <p>需求领域：{{ this.Info.typeSecond }}</p>
          <p>开始时间：{{ this.Info.beginTimeFormat }}</p>
          <p>项目预算：￥{{ this.Info.budget }}</p>
        </div>
      </div>
      <div class="right">
        <header>
          <button>正在设计</button>
          <button>正在服务</button>
          <button>正在保障</button>
        </header>
        <div class="right_line">
          <p>当前进度：</p>
          <div class="circle">
          </div>
          <i>-----------</i>
          <div class="circle">

          </div>
          <i>-----------</i>
          <div class="circle">

          </div>
          <i>-----------</i>
          <div class="circle">

          </div>
          <i>-----------</i>
          <div class="circle">

          </div>
          <i>-----------</i>
          <div class="circle">

          </div>
          <i>-----------</i>
          <div class="circle">

          </div>
          <i>-----------</i>
          <div class="circle">

          </div>
          <i>-----------</i>
          <div class="circle">

          </div>


        </div>

        <div class="line_content">
          <p>客户发布</p>
          <p>任务报名</p>
          <p>客户选标</p>
          <p>中标确定</p>
          <p>签订协议</p>
          <p>服务阶段</p>
          <p>平台质检</p>
          <p>验收付款</p>
          <p>评价结束</p>
        </div>
      </div>
    </div>
    <div class="listBac">
      <div class="userList">
        <div class="userInf">
          <el-scrollbar height="266px">
            <div v-for="(item,i) in this.userInf">
              <div class="userCard" style="float: left">
                <div class="avatar"></div>
                <div class="userName">
                  <div>{{ item.userMessage.username }}</div>
                  <div>123</div>
                  <div class="renzheng"></div>
                </div><br>
                <div class="area">
                  <div class="advantageField">
                    <div>擅长领域：</div>
                    <div>{{ item.userMessage.areaGood }} |</div>
                  </div>
                  <div class="address">
                    <div>地址：</div>
                    <div>{{ item.userMessage.address }}</div>
                  </div>
                </div>
                <button class="downloadButton" :style="item.fileStatus" @click="download(item)">下载文件</button>
                <button class="selectButton" @click="submit(item.userMessage.id)">入选</button>
              </div>
            </div>
          </el-scrollbar>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
let formData = new FormData();
export default {
  name: "BidDetermine",
  data() {
    return {
      ID: {
        pid: 1,
      },
      Info: '',
      userInf:'',
      winBid:{
        pid:'',
        uid:'',
      },
      downloadStyle:'',
    }
  },
  mounted() {
    this.getInfo();
    this.getUserInf();
  },
  methods: {
    getInfo() {
      //this.ID.pid = parseInt(this.$route.query.projectId);
      this.$http({
        method: 'post',
        url: '/getProjectMessageAndSchedule',
        data: this.ID
      }).then(res => {
        this.Info = res.data.projectMessage;
      })
    },
    getUserInf(){
      this.$http({
        method: 'post',
        url: '/allSelectedMessageAndFileStatus',
        data: this.ID
      }).then(res => {
        this.userInf = res.data;
        for(let i=0;i<this.userInf.length;i++){
          if (this.userInf[i].fileStatus === 3){
            this.userInf[i].fileStatus = "background-color: #5c5c5c";
          }else {
            this.userInf[i].fileStatus = null;
          }
        }

      })
    },
    submit(index){
      this.winBid.pid = this.ID.pid;
      this.winBid.uid = index;
      this.$http({
        method: 'post',
        url: '/selectWinBidUser',
        data: this.winBid,

      }).then(res => {

        // if (res.data.code === 1){
        //   this.$notify({
        //     title: '上传成功',
        //     message: '',
        //     type: 'success'
        //   });
        // }
      })
    },
    download(index){
      this.$http({
        method: 'post',
        url: '/selectWinBidUser',
        data: this.winBid,

      }).then(res => {

        // if (res.data.code === 1){
        //   this.$notify({
        //     title: '上传成功',
        //     message: '',
        //     type: 'success'
        //   });
        // }
      })
    }
  }

}
</script>

<style scoped>
.bac {
  width: 1920px;
  padding-left: 298px;
  padding-top: 50px;
  background-color: #f5f5f5;
}


.top {
  position: relative;
  width: 1462px;
  height: 330px;
  /*border-radius: 5px;*/
  /*background-color: pink;*/
  padding-top: 50px;
  /*margin: 0 auto 50px;*/
  background-color: #ffffff;
  /*box-shadow: 0 0 5px rgba(0,0,0,.2);*/
  margin-left: 70px;
  margin-bottom: 35px;
}

.top .label {
  position: absolute;
  width: 77px;
  height: 50px;
  top: 37px;
  right: 0px;
}

.top .label img {
  width: 100%;
  height: 100%;
}

.left {
  float: left;
  width: 298px;
  height: 244px;
  margin-left: 60px;
  border-right: 1px solid #a4a4a4;

}

.top .left_top p:nth-child(1) {
  height: 24px;
  color: #212121;
  font-size: 24px;
  margin-bottom: 20px;
}

.top .left_top p:nth-child(2) {
  height: 36px;
  color: #212121;
  font-size: 36px;
  margin-bottom: 65px;
}

.top .left_bottom {
  height: 86px;
  font-size: 18px;
  color: #a4a4a4;

}

.top .left_bottom p {
  height: 18px;
  margin-bottom: 19px;
}

.right {
  float: left;
  height: 244px;
  margin-left: 56px;

}

.right header{
  height: 25px;
  margin-bottom: 28px;
}

.right header button{
  width: 75px;
  height: 25px;
  font-size: 14px;
  line-height: 25px;
  border-radius: 5px;
  color: #ffffff;
  background-color: #fe655f;
  margin-right: 10px;
}



.right .itemButton {
  margin-bottom: 35px;
}

.right .itemButton button {
  width: 90px;
  height: 30px;
  font-size: 14px;
  background-color: #ff655f;
  margin-right: 10px;
  color: #ffffff;
  border-radius: 5px;
}

.right .right_line {
  height: 110px;
}

.right .right_line p {
  font-size: 18px;
  color: #212121;
  margin-bottom: 39px;
}

.right .right_line i {
  text-align: center;
  width: 90px;
}

.right .right_line div {
  float: left;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ed404a;
}

.right .right_line div:nth-of-type(n+5) {
  background-color: grey;
}


.right .right_line i {
  float: left;
  height: 20px;
  color: #ed404a;
  letter-spacing: 2px;
  font-size: 15px;
  margin-top: -2px;
}

.right .right_line .circle:nth-child(2) {
  margin-left: 30px;
}

.right .line_content p {
  float: left;
  color: #212121;
  font-size: 18px;
  margin-right: 39px;
}

.right .line_content {
  height: 18px;
  margin-bottom: 30px;
}

.right .remind {
  position: relative;
  height: 14px;
}

.right .remind p {
  float: left;
  font-size: 14px;
  color: #adadad;
}

.right .remind i {
  float: left;
  margin-right: 5px;
}

.right .remind .eye {
  float: right;
  width: 45px;
  height: 18px;
  position: absolute;
  right: 120px;
}

.right .remind .eye span {
  height: 18px;
  float: left;
  font-size: 14px;
  line-height: 18px;
}


.right .remind .group {
  float: right;
  width: 45px;
  height: 18px;
  position: absolute;
  right: 60px;
}

.right .remind .group span {
  height: 18px;
  float: left;
  font-size: 14px;
  line-height: 18px;
}

.user_card_bottom {
  width: 1450px;
  height: 45px;
  background-color: #ffffff;
}

.user_card_bottom .real-name {
  float: left;
  width: 310px;
  height: 18px;
  margin-right: 643px;
  margin-left: 50px;
  border: 1px solid transparent;
  /*margin-top: 25px;*/
}

.user_card_bottom .real-name i {
  display: block;
  float: left;
  width: 10px;
  height: 10px;
  line-height: 10px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: #ec414d;
  text-align: center;
  color: #ffffff;
  margin-top: 3px;
}


.user_card_bottom .real-name p {
  float: left;
  font-size: 12px;
  color: #acaeab;
}

.user_card_bottom number {
  float: right;
  height: 18px;
}

.user_card_bottom .number p {
  float: left;
  font-size: 16px;
  color: #212121;
  margin-right: 38px;
}

.user_card_bottom .number div {
  float: left;
  font-size: 16px;
  color: #ec414d;
  margin-right: 90px;
}

.listBac{
  position: relative;
  width: 1460px;
  height: 472px;
  margin-left: 70px;
  background-color: #ffffff;
  padding-top: 58px;
  padding-left: 78px;
}

.listBac .confirm{
  position: absolute;
  right: 142px;
  bottom: 18px;
  width: 190px;
  height: 62px;
  font-size: 26px;
  color: #ffffff;
  line-height: 62px;
  background-color: #fe655f;
  border-radius: 5px;
}

.userInf .userCard{
  position: relative;
  width: 596px;
  height: 122px;
  margin-bottom: 14px;
  margin-right: 65px;
  background-color: #ececec;
  padding-top: 24px;
  border: 1px solid transparent;

}

.userInf .userCard .selectButton,.downloadButton{
  position: absolute;
  right: 138px;
  bottom: 40px;
  width: 110px;
  height: 40px;
  /*margin-top: 15px;*/
  color: #fef6f6;
  background-color: #fe655f;
  /*border: 1px solid transparent;*/
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.userInf .userCard .downloadButton{
  right: 15px;
}

.userInf .userCard .avatar{
  float: left;
  width: 78px;
  height: 78px;
  margin-left: 50px;
  margin-right: 20px;
  border-radius: 50%;
  background-image: url("../../../../images/tx.png");
  background-size: 100% 100%;
  /*background-color: #1ea1e0;*/
  /*margin-top: 19px;*/
}

.userInf .userCard .projectName{
  float: left;
  /*float: left;*/
  width: 242px;
  height: 16px;
  font-size: 16px;
  margin-bottom: 20px;
  color: #212121;
  margin-top: 8px;
  margin-right: 125px;
}

.userInf .userCard .projectName div{
  float: left;
}

.userInf .userCard .area{
  float: left;
  height: 12px;
  width: 280px;
  /*font-size: 12px;*/
  color: #c5c5c5;
  line-height: 12px;
  font-size: 14px;
  /*margin-top: 13px;*/
}

.userInf .userCard .area div{
  float: left;
}

.userInf .userCard .area .address{
  margin-left: 6px;
  margin-right: 6px;
}

.userInf .userCard .userName{
  height: 22px;
  font-size: 20px;
  margin-top: 10px;
}

.userInf .userCard .userName div{
  float: left;
}

.userInf .userCard .renzheng{
  float: left;
  width: 20px;
  height: 22px;
  /*background-color: #ec414d;*/
  margin-left: 10px;
  margin-right: 40px;
  background-image: url("../../../../images/认证.png");
  background-size: 100% 100%;
}



</style>